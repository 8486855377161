import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export function createRouter() {
  return new Router({
    mode: 'history',
    routes: [
      {
        path: '/',
        name: 'home',
        component: () => import('./views/Home.vue'),
				props: {
					setState: ''
				}
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('./views/About.vue'),
				props: {
					setState: ''
				}
      },
      {
        path: '/news',
        name: 'news',
        component: () => import('./views/News.vue'),
				props: {
					setState: ''
				}
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('./views/Contact.vue'),
				props: {
					setState: ''
				}
      },
      {
        path: '/team',
        name: 'our-team',
        component: () => import('./views/Team.vue'),
				props: {
					setState: ''
				}
      },
      {
        path: '/estates',
        name: 'our-estates',
        component: () => import('./views/Estates.vue'),
				props: {
					setState: ''
				}
      },
      {
        path: '/lifestyle',
        name: 'lifestyle',
        component: () => import('./views/Lifestyle.vue'),
				props: {
					setState: ''
				}
      },
      {
        path: '/winelands',
        name: 'winelands',
        component: () => import('./views/Winelands.vue'),
				props: {
					setState: ''
				}
      },
      {
        path: '/developments',
        name: 'developments',
        component: () => import('./views/Developments.vue'),
				props: {
					setState: ''
				}
      },
      {
        path: '/properties',
        name: 'portfolio',
        component: () => import('./views/Portfolio.vue'),
				props: {
					setState: ''
				}
      },
      {
        path: '/global-connect',
        name: 'global',
        component: () => import('./views/Global.vue'),
				props: {
					setState: ''
				}
      },
      {
        path: '/property/:propertyId',
        component: () => import('./views/Property.vue'),
				props: {
					setState: ''
				}
      },
      {
        path: '/commercial/:propertyId',
        component: () => import('./views/Commercial.vue'),
				props: {
					setState: ''
				}
      },
      {
        path: '/news/:articleSlug',
        component: () => import('./views/News.vue'),
				props: {
					setState: ''
				}
      },
    ],
	  scrollBehavior() {
	      return {x: 0, y: 0}
	  }
  })
}
